<template>
  <div class="">
    <!-- heading selection part  -->
    <h5 class="fw-light text-white">
      Lead Quizzes > <span class="fw-bold text-white">Index</span>
    </h5>
    <div
      class="d-flex flex-column flex-sm-row justify-content-between align-items-md-center py-5 mb-10 custom-title-width">
      <h1 class="text-white quiz-heading fw-light">
        Lead <span class="fw-bold">Quizzes</span>
      </h1>
      <div class="d-md-flex gap-6 align-items-end">
        <!-- for quiz type  -->
        <div class="mb-4 mb-md-0">
          <label class="fs-7 mb-2 text-secondary" for="">Select Quiz Type
          </label>
          <select name="" id=""
            class="form-select custom-select form-select-solid group-dropdown fs-7 w-100 w-md-150px p-2"
            aria-label="Lead Quizzes">
            <option selected disabled hidden value="">Lead Quizzes</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
        <!-- for Category  -->
        <div>
          <label class="fs-7 mb-2 text-secondary" for="">Select Category</label>
          <select name="" id=""
            class="form-select custom-select form-select-solid group-dropdown fs-7 w-100 w-md-150px p-2" aria-label="all"
            v-model="sort_group_id" @change="getquizData(current_page)">
            <option class="text-muted" value="">View By Group</option>
            <option v-for="(g, index) in getquizGroup" :key="index" :value="g.id">
              {{ g.name }}
            </option>
          </select>
        </div>
        <button type="button" class="btn d-lg-block d-none btn-primary justify-self-center btn-custom-primary fs-5"
          @click="showmodelQuiz()">
          New Quiz
        </button>
      </div>
    </div>
    <!-- lead quizes gallary  -->
    <div class="" style="--bs-gutter-y: 0rem; --bs-gutter-x: 0rem">
      <!-- quiz lead gallary container   -->
      <div class="d-flex gap-10 gap-lg- flex-wrap justify-content-center justify-content-lg-start">
        <!-- quiz lead create card  -->
        <div
          class="lead-add-card w-md-300px w-lg-275px card-width rounded d-none d-md-flex flex-column align-items-center justify-content-center"
          @click="showmodelQuiz()">
          <i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="36" height="36" fill="#999BB3"
              style="margin-top: 15px">
              <path
                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z">
              </path>
            </svg></i>
          <h4 class="fw-semi-bold mt-2 fs-3">Create</h4>
          <h5 class="fs-3 fw-light">Lead Quiz</h5>
        </div>
        <div class="w-100 w-md-300px w-lg-275px card-width" v-for="(q, index) in getquizDataGT.data" :key="index">
          <div class="">
            <!-- <img :src="`${assetUrl}/assets/img/templates/template_${q.template}.png`" alt="" class="image-sizing" @click="viewQuizDetail(q)" /> -->
            <div class="mini-preview-wrapper-new" @click="viewQuizDetail(q)">
              <div class="mini-preview-frame">
                <div class="tw-bg-[#ffffff] h-full">
                  <TemplateViewer v-if="q.questions && q.questions.length > 0" :dynamic_template="q.dynamic_template"
                    :template_css_data="filterTemplateCss(q)" :questions_data="q.questions" :quiz_data="q"
                    :is_preview_mode="true" />
                </div>
              </div>
            </div>

            <div class="px-3 py-5" style="border-bottom: 1px solid rgb(110, 111, 118)">
              <!-- <span class="fs-6 text-white fw-light">{{ q.name }} <span class="fs-8" v-if="q.is_copy">(Copy)</span></span> -->
              <div class="d-flex align-items-center">
                <div
                    class="funnel-name-edit d-flex align-items-end"
                    v-if="q.is_edit"
                >
                  <input
                      type="text"
                      class="funnel-name font-small"
                      v-model="q.name"
                  />
                  <i
                      class="fas fa-check cursor-pointer fs-larger ml-2 mb-1"
                      @click="saveQuizName(q, index)"
                  ></i>
                </div>
                <div class="funnel-name-show d-flex mb-1" v-else>
                  <!-- <h6 class="mb-0 font-small">{{ q.name }}</h6> -->
                  <h6 class="fs-6 text-white fw-light mb-0" :title="q.name">
                    {{
                      q.name.length > 20
                          ? q.name.substring(0, 20) + "..."
                          : q.name
                    }}
                  </h6>
                  <!-- <span v-if="q.is_cloned" class="font-weight-bold pl-1">(copy)</span> -->
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      @click="editQuizName(q, index)"
                      class="svg-show ml-2"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="#ffffff"
                  >
                    <path
                        opacity="0.3"
                        d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                        fill="#ffffff"
                    ></path>
                    <path
                        d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                        fill="#ffffff"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <!-- table content  -->
            <div class="d-flex align-items-center px-4 table-content">
              <table class="table table-borderless text-white fs-7 fw-light mt-3">
                <thead>
                  <tr>
                    <th scope="col">Views</th>
                    <th scope="col">Entries</th>
                    <th scope="col">Conv</th>
                    <th scope="col">Qual</th>
                    <th scope="col">Qual%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ q.views_count }}</td>
                    <td>{{ q.entries_count }}</td>
                    <td>{{ getConv(q) }}%</td>
                    <td>{{ q.qualified_entries_count }}</td>
                    <td>{{ getQualified(q) }}%</td>
                  </tr>
                </tbody>
              </table>
              <div class="btn-dot-manu-container dropup">
                <button data-bs-toggle="dropdown" class="btn btn-dots-manue ml-1">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="white">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                  </svg>
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-start manue-items-list rounded overflow-hidden dropdown-menu bg-black">
                  <!-- <li>
                    <a
                      role="menuitem"
                      href="javascript:void(0)"
                      target="_self"
                      class="dropdown-item"
                    >
                      Update Category
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="javascript:void(0)"
                      target="_self"
                      class="dropdown-item"
                    >
                      Trigger Integration
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="javascript:void(0)"
                      target="_self"
                      class="dropdown-item"
                    >
                      Fire Tags
                    </a>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      href="javascript:void(0)"
                      target="_self"
                      class="dropdown-item"
                    >
                      Setup URL'S
                    </a>
                  </li> -->
                  <li>
                    <router-link class="dropdown-item" :to="{ name: 'QuizDetailNew', params: { id: q.id } }">View Detail
                    </router-link>
                  </li>
                  <li>
                    <router-link class="dropdown-item"
                      :to="{ name: 'QuizDetailNew', params: { id: q.id }, query: { tab: 'entries' } }">View Entries
                    </router-link>
                  </li>
                  <li>
                    <router-link to="" class="dropdown-item" @click="openUrlModel(q)">Setup Url's</router-link>
                  </li>
                  <li>
                    <router-link to="" class="dropdown-item" @click="openAutomationModelFunc(q)">Quiz Tag</router-link>
                  </li>
                  <li>
                    <router-link class="dropdown-item"
                      :to="{ name: 'QuizDetailNew', params: { id: q.id }, query: { tab: 'statistics' } }">View Statistics
                    </router-link>
                  </li>
                  <li>
                    <a role="menuitem" href="javascript:void(0)" target="_self" class="dropdown-item"
                      @click="deleteConQuiz(q.id)">
                      Delete Lead Quiz
                    </a>
                  </li>
                  <li>
                    <a role="menuitem" href="javascript:void(0)" target="_self" class="dropdown-item"
                      @click="editQuiz(q)">
                      Edit Lead Quiz
                    </a>
                  </li>
                  <li>
                    <a role="menuitem" href="javascript:void(0)" target="_self" class="dropdown-item"
                      @click="cloneQuizData(q)">
                      Clone Lead Quiz
                    </a>
                  </li>
                  <li>
                    <hr role="separator" aria-orientation="horizontal" class="dropdown-divider" />
                  </li>
                  <li>
                    <a role="menuitem" href="javascript:void(0)" target="_self" class="dropdown-item"
                      @click="copyLink(q)">
                      Copy Url
                    </a>
                  </li>

                  <li>
                    <a role="menuitem" href="javascript:void(0)" target="_self" class="dropdown-item"
                      @click="goToLink(q)">
                      Go To Link
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Pagination :data="getquizDataGT && getquizDataGT.data ? getquizDataGT : []" @pagination-change-page="getquizData"
      :show-disabled="true" class="mt-10" />

  </div>

  <Model modaltitle="Setup Urls" v-if="showmodalsetupUrl" @close="CloseModelsetupUrl()">
    <template #body>
      <form @submit.prevent="saveLink()">
        <div class="modal-body">
          <label>STEP #1- DOMAIN SETUP</label>

          <!-- <div class="d-flex justify-content-between align-items-center mt-4">
            <p class="mb-0 w-100">Domain Settings</p>
            <div class="input-group d-flex justify-content-end">
              <input type="radio" v-model="domainType" class="mx-2" name="radio1" id="radio-small" value="1" />
              <label for="radio-small">Personal</label>
              <input type="radio" v-model="domainType" class="mx-2 ms-5" name="radio1" value="2" id="radio-big" />
              <label for="radio-big">System Domain</label>
            </div>
          </div> -->
          <div class="mt-5">
            <div class="w-50">
              <select class="form-select form-select-sm" v-model="systemDomain" v-if="domainType == 2"
                aria-label="Default select example">
                <option value="https://api.quizforma.com" v-for="(domain, index) in systemDomainList" :key="index">{{
                  domain }}</option>
              </select>
              <select class="form-select form-select-sm" v-model="personalDomain" v-else
                aria-label="Default select example">
                <option :value="null">Use Default Domain</option>
                <option v-for="(domain, index) in getDomainsData" :key="index" :value="domain.id">{{
                  domain.name }}</option>
              </select>
            </div>
          </div>

          <hr class="my-5">

          <label>Step #2 - Set URL Path</label>

          <div class="mt-4">
            <div class="w-50">
              <input type="text" v-model="keyword" :class="{
                'is-invalid custom-border': v$.keyword.$errors.length,
              }" class="form-control form-control-sm" aria-label="Default select example">
              <p class="text-sm mt-1 text-danger" v-if="v$.keyword.$errors.length">Invalid keyword</p>
            </div>
          </div>
          <div class="mt-3 ml-2" v-if="keyword">
            <p class="text-sm">URL: <span class="text-primary"> https://{{ getDomainUrl(personalDomain) }}{{ keyword ? `/${keyword}` : ''
            }}</span></p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal" @click="CloseModelsetupUrl()">
            Close
          </button>
          <button type="submit" class="btn btn-primary">Save Link</button>
        </div>
      </form>
    </template>
  </Model>

  <Model modaltitle="Quiz Tag" v-if="showmodalAutomation" @close="CloseModelAutomation()">
    <template #body>
      <form @submit.prevent="saveQuizTag()">
        <div class="modal-body">

          <div class="mt-4">
            <p class="mb-0 w-100">Standard</p>
            <div class="input-group d-flex justify-content-end mt-3">
              <div class="w-100">
                <select class="form-select form-select-sm"
                  aria-label="Default select example" v-model="quizTag.standard_id">
                  <option value="null" :selected="!quizTag.standard_id" disabled>Select tag</option>
                  <option :value="tag._id" v-for="(tag, index) in getTagsData" :key="index">{{tag.name }}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="mt-5">
            <p class="mb-0 w-100">Qualified</p>
            <div class="input-group d-flex justify-content-end mt-3">
              <div class="w-100">
                <select class="form-select form-select-sm"
                  aria-label="Default select example" v-model="quizTag.qualified_id">
                  <option value="null" :selected="!quizTag.qualified_id" disabled>Select tag</option>
                  <option :value="tag._id" v-for="(tag, index) in getTagsData" :key="index">{{tag.name }}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="mt-6">
            <p class="mb-0 w-100">Disqualified</p>
            <div class="input-group d-flex justify-content-end mt-3">
              <div class="w-100">
                <select class="form-select form-select-sm"
                  aria-label="Default select example" v-model="quizTag.disqualified_id">
                  <option value="null" :selected="!quizTag.disqualified_id" disabled>Select tag</option>
                  <option :value="tag._id" v-for="(tag, index) in getTagsData" :key="index">{{tag.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal" @click="CloseModelAutomation()">
            Close
          </button>
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </form>
    </template>
  </Model>

  <Model :modaltitle="modaltitle" @close="CloseModelQuiz()" v-if="showmodalQuiz">
    <template #body>
      <form method="post" @submit.prevent="addQuiz()">
        <div class="modal-body">
          <div class="mb-10 fv-row fv-plugins-icon-container">
            <label for="exampleFormControlInput1" class="required form-label">Name of Quiz</label>
            <input type="text" class="form-control form-control-solid" :class="{
              'is-invalid custom-border': v$.quiz.name.$errors.length,
            }" v-model="v$.quiz.name.$model" placeholder="Enter Name of Quiz" />
            <span class="invalid-feedback" role="alert" v-for="(error, index) of v$.quiz.name.$errors" :key="index">
              <strong>Name is required</strong>
            </span>
          </div>
          <div class="mb-10 fv-row fv-plugins-icon-container">
            <label for="" class="form-label">Quiz Group</label>
            <select class="form-select form-select-solid" v-model="quiz.group_id">
              <option :value="null">Select Group</option>
              <option v-for="(g, index) in getquizGroup" :key="index" :value="g.id">
                {{ g.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal" @click="CloseModelQuiz()">
            Close
          </button>
          <button type="submit" class="btn btn-primary">Save changes</button>
        </div>
      </form>
    </template>
  </Model>
  <Model :modaltitle="modaltitle" @close="CloseModelQuizGroup()" v-if="showmodalQuizGroup">
    <template #body>
      <form method="post" @submit.prevent="addGroup()">
        <div class="modal-body">
          <div class="mb-10 fv-row fv-plugins-icon-container">
            <label for="exampleFormControlInput1" class="required form-label">Group Name</label>
            <input type="text" class="form-control form-control-solid" :class="{
              'is-invalid custom-border': v$.quizGroup.name.$errors.length,
            }" v-model="v$.quizGroup.name.$model" placeholder="Enter Name of Quiz Group" />
            <span class="invalid-feedback" role="alert" v-for="(error, index) of v$.quizGroup.name.$errors" :key="index">
              <strong>Name is required</strong>
            </span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal" @click="CloseModelQuizGroup()">
            Close
          </button>
          <button type="submit" class="btn btn-primary">Save changes</button>
        </div>
      </form>
    </template>
  </Model>
</template>
<script>
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import Model from "@/components/custom-components/model.vue";
import TemplateViewer from "@/components/custom-components/quiz/quiz-templates/TemplateViewer.vue";

import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import _ from "lodash";
import moment from "moment";
import LaravelVuePagination from 'laravel-vue-pagination';


export default defineComponent({
  name: "dashboard",
  components: {
    Model,
    TemplateViewer,
    Pagination: LaravelVuePagination,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Quiz");
    });
    const toast = useToast();
    return { toast, v$: useVuelidate() };
  },
  data() {
    return {
      showmodalQuiz: false,
      showmodalsetupUrl: false,
      showmodalAutomation: false,
      selectedQuiz: null,
      showmodalQuizGroup: false,
      quiz_serch: "",
      active_tab: "quiz",
      modaltitle: "Add Quiz",
      quiz: {
        name: "",
        group_id: "",
      },
      quizGroup: {
        name: "",
      },
      current_page: 1,
      sort_group_id: "",
      quiz_name_search: "",
      loader: false,
      assetUrl: process.env.VUE_APP_ENVIROMENT != 'local' ? process.env.VUE_APP_API_URL : "http://127.0.0.1:8000",

      link_id: null,
      domainType: 1,
      personalDomain: null,
      systemDomain: null,
      systemDomainList: ['api.quizforma.com'],
      keyword: null,

      quizTag : {
        standard_id : null,
        qualified_id : null,
        disqualified_id : null ,
      }
    };
  },
  validations() {
    return {
      quiz: {
        name: { required },
      },
      quizGroup: {
        name: { required },
      },
      keyword: { space: helpers.regex(/^((?! )(?!\/)(?!\\).)*$/) }
    };
  },
  computed: {
    ...mapGetters({
      getquizGroup: "Quiz/getquizGroupData",
      getquizDataGT: "Quiz/getquizData",
      getTagsData: 'GC/getTagsData',
      getDomainsData:'Domain/getDomainsData',
    }),
    format_date() {
      return (value) => {
        return moment(value).format("MM/DD/YYYY");
      };
    },
  },
  methods: {
    ...mapActions({
      addQuizGroup: "Quiz/addQuizGroup",
      addQuizData: "Quiz/addQuiz",
      setquizGroup: "Quiz/getQuizGroup",
      setgetQuiz: "Quiz/getQuiz",
      deleteQuizGroup: "Quiz/deleteGroup",
      deleteQuiz: "Quiz/deleteQuiz",
      cloneQuiz: "Quiz/cloneQuiz",
      getallTemplate: "Quiz/getallTemplate",
      setupUrl: "Quiz/setupUrl",
      quizTagUpdate: "Quiz/quizTagUpdate",
      getTagList:'GC/getTagList',
      fetchDomainList:'Domain/fetchDomainList',
      updateQuizName:'Quiz/updateQuizName',
    }),
    cloneQuizData(q) {
      this.$swal
        .fire({
          title: "Are you sure you want to copy?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, copy it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loader = true
            this.cloneQuiz(q.id).then((res) => {
              this.loader = false
              if (res.data.status == true) {
                this.$swal.fire(
                  "Copied!",
                  "Quiz Copied Successfully.",
                  "success"
                );
              }
            })
              .catch((error) => {
                this.loader = false
                this.toast.error(error.response.data.message);
              });
          }
        });
      // this.cloneQuiz(q.id).then((res)=>{
      //   if(res.data.status==true){
      //   this.toast.success('Quiz Copied Successfully','Copied!');
      //   }
      // })
    },
    eventcheck() {
      console.log(121)
    },
    openUrlModel(quiz) {
      this.selectedQuiz = quiz.id;
      this.showmodalsetupUrl = true

      if (quiz.links ) {
        this.link_id = quiz.links.id
        this.selectedQuiz = quiz.links.quiz_id,
        this.domainType = 1
        this.personalDomain = quiz.links.personal_domain_id
        this.systemDomain = quiz.links.system_domain
        this.keyword = quiz.links.domain_type == 2 ? null : quiz.links.keyword
      } else {
        this.link_id = null
        this.domainType = 1;
        this.personalDomain = null;
        this.systemDomain = null;
        this.keyword = null;
      }
    },
    openAutomationModelFunc(quiz) {
      this.selectedQuiz = quiz.id;
      this.showmodalAutomation = true
      this.quizTag.standard_id = quiz.standard_id || null;
      this.quizTag.qualified_id = quiz.qualified_id || null;
      this.quizTag.disqualified_id = quiz.disqualified_id || null;
    },
    saveQuizTag() {

      let payload = {
        quiz_id: this.selectedQuiz,
        ...this.quizTag
      }
      this.quizTagUpdate(payload).then(
        (res) => {
          if (res.data.status) {
            this.showmodalAutomation = false;
            this.toast.success('Quiz Tag Updated Successfully');
          } else {
            if (!res.data.status) {
              this.toast.error(res.data.message);
            }
          }
        }
      ).catch((error) => {
        this.toast.error(error.message);
      });
    },
    saveLink() {
      this.v$.keyword.$touch();
      if (this.v$.keyword.$invalid) {
        return
      }

      let payload = {
        id: this.link_id,
        quiz_id: this.selectedQuiz,
        type: this.domainType,
        personal_domain: this.personalDomain,
        system_domain: this.systemDomain,
        keyword: this.keyword
      }
      this.setupUrl(payload).then(
        (res) => {
          if (res.data.status) {
            this.showmodalsetupUrl = false;
            this.toast.success('Link Save Successfully');
          } else {
            if (!res.data.status) {
              this.toast.error(res.data.message);
            }
          }
        }
      ).catch((error) => {
        this.toast.error(error.message);
      });
    },
    search_group: _.debounce(function () {
      this.getAllgroups();
    }, 500),
    search_quiz: _.debounce(function () {
      this.getquizData();
    }, 500),
    showmodelQuiz() {
      (this.modaltitle = "Add Quiz"),
        (this.quiz = {
          id: "",
          name: "",
          group_id: "",
        });
      this.showmodalQuiz = true;
      this.v$.quiz.$reset();
    },
    showmodelQuizGroup() {
      (this.modaltitle = "Add Quiz Group"),
        (this.quizGroup = {
          name: "",
        });
      this.showmodalQuizGroup = true;
      this.v$.quizGroup.$reset();
    },
    CloseModelQuiz() {
      this.showmodalQuiz = false;
    },
    CloseModelsetupUrl() {
      this.showmodalsetupUrl = false;
      this.selectedQuiz = null;
    },
    CloseModelAutomation() {
      this.showmodalAutomation = false;
      this.selectedQuiz = null;
    },
    copyScript(data) {
      let copyUrl = process.env.VUE_APP_API_URL + "/page/quiz/" + data.unique_code
      var scriptRoute = process.env.VUE_APP_API_URL + '/assets/quiz/script.js'
      // eslint-disable-next-line no-useless-escape
      var iFrame = '<iframe id="QuizForma-' + data.unique_code + '" onload="window.parent.scrollTo(0,0)" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src="' + copyUrl + '" frameborder="0" width="100%" height="539px" scrolling="no"></iframe><script type="text/javascript" src="' + scriptRoute + '" quiz-unique-code="' + data.unique_code + '"></\script>'
      document.addEventListener('copy', function (e) {
        e.clipboardData.setData('text/plain', iFrame);
        e.preventDefault();
      }, true);
      document.execCommand('copy');
      this.toast.success('Script Copied Successfully', 'Copied!');
    },
    editQuizGroup(data) {
      (this.modaltitle = "Edit Quiz Group"),
        (this.quizGroup = {
          name: data.name,
          id: data.id,
        });
      this.showmodalQuizGroup = true;
      this.v$.quizGroup.$reset();
    },
    editQuiz(data) {
      (this.modaltitle = "Update Quiz"),
        (this.quiz = {
          id: data.id,
          name: data.name,
          group_id: data.group_id,
        });
      this.showmodalQuiz = true;
      this.v$.quiz.$reset();
    },
    CloseModelQuizGroup() {
      this.showmodalQuizGroup = false;
    },
    addQuiz() {
      this.v$.quiz.$touch();
      if (this.v$.quiz.$invalid) {
        return;
      }
      this.addQuizData(this.quiz)
        .then((response) => {
          if (response.data.status == true) {
            console.log('res', response)
            if (response.data.data) {
              var quizData = response.data.data.quiz
              if (quizData) {
                this.$router.push({ name: 'QuizDetailNew', params: { id: quizData.id }, query: { tab: 'template', mode: 'gallery' } })
              }
            }
            this.showmodalQuiz = false;
          }
          this.sort_group_id = "";
          this.quiz_name_search = "";
          //   this.quiz_serch=''
        })
        .catch((error) => {
          this.toast.error(error.response.data.message);
        });
    },
    addGroup() {
      this.v$.quizGroup.$touch();
      if (this.v$.quizGroup.$invalid) {
        return;
      }
      this.addQuizGroup(this.quizGroup)
        .then((response) => {
          if (response.data.status == true) {
            this.showmodalQuizGroup = false;
          }
          this.quiz_serch = "";
        })
        .catch((error) => {
          this.toast.error(error.response.data.message);
        });
    },
    deleteGroup(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteQuizGroup(id)
              .then((response) => {
                if (response.data.status == true) {
                  this.$swal.fire(
                    "Deleted!",
                    "Quiz Group has been deleted.",
                    "success"
                  );
                  this.quiz_serch = "";
                }
              })
              .catch((error) => {
                this.toast.error(error.response.data.message);
              });
          }
        });
    },
    deleteConQuiz(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteQuiz(id)
              .then((response) => {
                if (response.data.status == true) {
                  this.$swal.fire(
                    "Deleted!",
                    "Quiz has been deleted.",
                    "success"
                  );
                  this.getquizData(this.current_page);
                }
              })
              .catch((error) => {
                this.toast.error(error.response.data.message);
              });
          }
        });
    },
    getAllgroups() {
      this.setquizGroup(this.quiz_serch);
    },
    getquizData(page = 1) {
      this.current_page = page;
      let data = {
        page: page,
        sort_group_id: this.sort_group_id,
        query: this.quiz_name_search,
      };
      this.setgetQuiz(data);
    },
    copyLink(quiz) {

      let copyUrl = process.env.VUE_APP_API_URL + "/page/quiz/" + quiz.unique_code
      if (quiz.links && quiz.links.keyword) {
        if(quiz.links.domain_type == 1){
          copyUrl = `https://${this.getDomainUrl(quiz.links.personal_domain_id)}/${quiz.links.keyword}`
        }else if(quiz.links.domain_type == 2){
          copyUrl = process.env.VUE_APP_API_URL + "/" + quiz.links.keyword
        }
      }

      // let copyUrl = process.env.VUE_APP_API_URL + "/page/quiz/" + uniqueCode
      document.addEventListener('copy', function (e) {
        e.clipboardData.setData('text/plain', copyUrl);
        e.preventDefault();
      }, true);
      document.execCommand('copy');
      this.toast.success('Link Copied Successfully', 'Copied!');
      // window.open(copyUrl, '_blank');

    },
    goToLink(quiz) {
      // let copyUrl = process.env.VUE_APP_API_URL + "/page/quiz/" + uniqueCode

      let copyUrl = process.env.VUE_APP_API_URL + "/page/quiz/" + quiz.unique_code
      if (quiz.links && quiz.links.keyword) {
        if(quiz.links.domain_type == 1){
          copyUrl = `https://${this.getDomainUrl(quiz.links.personal_domain_id)}/${quiz.links.keyword}`
        }else if(quiz.links.domain_type == 2){
          copyUrl = process.env.VUE_APP_API_URL + "/" + quiz.links.keyword
        }
      }
      window.open(copyUrl, '_blank');

    },
    viewQuizDetail(quiz) {
      this.$router.push({ name: 'QuizDetailNew', params: { id: quiz.id } })
      // this.$router.push({name: 'QuizDetailNew', params: { id: quiz.id }, query: { tab: 'template', mode: 'editor' }})
    },
    filterTemplateCss(quiz) {
      if (quiz && quiz.quiz_template_csses && quiz.quiz_template_csses.length > 0) {
        return quiz.quiz_template_csses.filter((css) => css.template_id == quiz.template)
      } else {
        return []
      }
    },
    getConv(quiz) {
      if (quiz.views_count > 0 && quiz.entries_count > 0) {
        var conv = (quiz.entries_count / quiz.views_count) * 100
        return conv > 100 ? 100 : Math.round(conv)
      } else {
        return 0
      }
    },
    getQualified(quiz) {
      if (quiz.qualified_entries_count > 0 && quiz.entries_count > 0) {
        var Qualified = (quiz.qualified_entries_count / quiz.entries_count) * 100
        return Qualified > 100 ? 100 : Math.round(Qualified)
      } else {
        return 0
      }
    },
    getDomainUrl(domain_id) {
      var domain = this.getDomainsData.find((val) => val.id == domain_id);
      if (domain) {
        return domain.domain;
      }else{
        return "api.quizforma.com"
      }
    },
    saveQuizName(quiz) {
      if (quiz.name) {
        let payload = {
          id: quiz.id,
          name: quiz.name,
        };
        this.updateQuizName(payload).then((res) => {
          if (res.data.status) {
            quiz.is_edit = false;
            quiz.old_name = quiz.name;
            this.$forceUpdate();
          } else {
            let message = res.data.message;
            this.toast.error(message);
          }
        });
      } else {
        quiz.is_edit = false;
        quiz.name = quiz.old_name;
        this.$forceUpdate();
      }
    },
    editQuizName(quiz) {
      quiz.is_edit = true;
      this.$forceUpdate();
      // this.updateFunnelEditMode(funnel)
    },
  },
  mounted() {
    this.getquizData();
    this.getAllgroups();
    this.getTagList()
    //Load templates data once on main page so we no need to call apis on each quiz
    this.getallTemplate();
    this.fetchDomainList()
  },
});
</script>
<!-- <style scoped>
.parent {
  font-weight: bold;
  text-transform: uppercase;
}
.group-dropdown {
  margin-right: 15px;
}
.add-button {
  width: 65px !important;
}
.search-input {
  margin-right: 15px;
}
.edit-name {
  padding: 1px 10px 1px 6px;
  margin-right: 6px;
  width: 50%;
  border: 2px dashed #eff2f5;
}
.edit-name-show {
  padding: 1px 5px 1px 6px;
}
.tag-modal {
  max-width: 650px;
}
.border-2 {
  color: #ffffff !important;
}
.custom-border {
  border-color: #f1416c !important;
}
</style> -->
<style scoped lang="scss">
.btn-dot-manu-container {
  position: relative;

  ul {
    padding: 0.4rem 0;
    margin: 0 !important;

    li {
      list-style: none;
      padding: 0;
      margin: 0;

      a {
        padding: 0.8rem 1rem;
        display: inline-block;
        color: white;
      }

      a:hover {
        color: black;
      }
    }

    hr {
      opacity: 1;
    }

    hr.dropdown-divider {
      border-top: 1px solid #fff;
    }
  }
}

.quiz-heading {
  font-size: 35px !important;
}

.btn {
  height: fit-content !important;
}

.btn-dots-manue {
  padding: 0.3rem !important;
  transform: scale(1.1);

  ul {
    position: absolute;
    margin: 0px;
    transform: translate(-2px, -36px);
    inset: auto auto 0px 0px;
  }
}

.btn-stats {
  padding: 1px !important;
  height: fit-content !important;
}

.card-width {
  min-height: 248px;
  background: #0B1636;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lead-add-card {
  cursor: pointer;
  background: #0B1636;

  h4,
  h5 {
    color: #999bb3 !important;
  }
}

.image-sizing {
  object-fit: fill;
  object-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 130px;
  image-rendering: -webkit-optimize-contrast;
  cursor: pointer;
}

.remove-default-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.table-content {
  height: 50px !important;
  margin: 7px 0;
}

.table> :not(caption)>*>* {
  text-align: center;
  padding: 0.1rem 0.2rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 00px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

th {
  padding-bottom: 0.5rem !important;
}

select {
  cursor: pointer;
}

@media (min-width: 200px) and (max-width: 1300px) {
  .quiz-heading {
    font-size: 28px !important;
    padding: 1rem 0;
  }

  .lead-quiz-gallary {
    justify-content: center !important;
  }
}

@media (min-width: 200px) and (max-width: 500px) {
  ul {
    position: absolute !important;
    inset: auto 0px 0px auto !important;
    margin: 0px !important;
    transform: translate3d(14px, -33.5px, 0px) !important;
  }
}

@media (min-width: 200px) and (max-width: 900px) {
  btn-dot-manu-container {
    ul {
      position: absolute;
      inset: auto 0px 0px auto !important;
      margin: 0px;
      transform: translate3d(14px, -33.5px, 0px) !important;
    }
  }
}

@media (min-width: 1440px) {
  .card-width {
    // width: 275px !important;
    width: 293px !important;
  }
}

.custom-select.form-select-solid {
  background-color: #0B1636;
  border-color: #0B1636;
  color: #E4E6EF;
}

.btn-custom-primary {
  background-color: #0B1636;
}


.mini-preview-wrapper-new {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  overflow: hidden;
  position: relative;
  // margin-top: -4px;
  transition: z-index steps(1) 0.3s, opacity 0.3s, margin-top 0.3s;
  cursor: pointer;
  height: 128px;
}


.mini-preview-anchor:hover .mini-preview-wrapper {
  z-index: 2;
  opacity: 1;
  margin-top: 6px;
  transition: opacity 0.3s, margin-top 0.3s;
}

.mini-preview-anchor {
  display: inline-block;
  position: relative;
  white-space: nowrap;
}

.mini-preview-frame {
  border: none;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  padding: 0 !important;
  -webkit-transform: scale(0.25) !important;
  transform: scale(0.18) !important;
  pointer-events: none;
  width: 576%;
  height: 563% !important;
  overflow: hidden;
}

.svg-show {
  width: 18px;
  display: none;
  cursor: pointer;
}

.funnel-name-show:hover > .svg-show {
  display: block;
}


.funnel-name {
  border: 0px;
  outline: 0px;
  border-bottom: 1px dashed #fff;
  background: transparent;
  color: #fff;
}
</style>
